<template>
  <div>
    <div class="div-companies content-wrapper">

      <section class="content-header p-1 px-2">
      <div class=" div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
              <div class="col-sm-12 d-flex justify-content-between align-items-center">
              <!-- <span></span> -->

              <h4 style="color: #0007" class="mt-3">
                <i class="fa fa-pager"></i>
                Apresentação</h4>
              </div>
          </div>
      </div><!-- /.container-fluid -->
      </section>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Total de empresas. <b>{{companies.length}}</b></h3>

                <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 400px; border-radius: 50px !important">
                    <input @keyup="getFilterCompanies()" v-model="searchCompanies"
                      style=" border-radius: 2px !important"
                      title="Pesquisar Empresa"
                      type="text"
                      name="table_search"
                      class="form-control float-right"
                      :placeholder="`Pesquisar Empresa por nome, id ou tipo`"
                    />

                    <button class="btn btn-dark btn-sm ml-1" title="atualizar" @click="updateApresentation()">
                      Atualizar <i style="font-size: 15px" class="fas fa-sync"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0" style="max-height: 70vh;">
                <table class="table table-hover table-stripped table-head-fixed">
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" :value="true" v-model="checkAllCompanies">
						</th>
						<th>Nome</th>
						<th>Tipo</th>
						</tr>
                  </thead>
                  <tbody>
                    <tr v-for="(company, i) in companies" :key="i" >
                      <td>
						<input type="checkbox" 
							:value="company._id" :checked="company.modalGeneral"
							v-model="companiesChecks">
					  </td>
                      <td>{{company.name}}</td>
                      <td >
                        <span v-if="company.account_type == 'basic'" class="badge bg-info">Basic</span>
                        <span v-if="company.account_type == 'essential'" class="badge bg-success">Light</span>
                        <span v-if="company.account_type == 'advanced'" class="badge bg-primary">Basic</span>
                        <span v-if="company.account_type == 'professional'" class="badge bg-dark">Pro</span>
                        <span v-if="company.account_type == 'premium'" class="badge bg-warning">Premium</span>
                        <span v-if="company.account_type == 'trial'" class="badge bg-danger">Trial</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { adminKey } from "@/config/global";
export default {
	data() {
		return {
		companies: [],
		searchCompanies: '',
		companiesGroup: [],
		companiesChecks: [],
		checkAllCompanies: false
		}
	},

	watch: {
		checkAllCompanies() {
			if (this.checkAllCompanies) {
				this.companiesChecks = this.companies.map(c => c._id)
			} else {
				this.companiesChecks = []
			}
		}
	},

	methods: {
		getCompanies() {
			this.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`
				
			this.$tallos_admin_api.get('companies/')
				.then(res => {
					this.companies = res.data
					this.companiesGroup = res.data

					this.companiesChecks = [...res.data.filter(c => c.modalGeneral === true).map(c => c._id)]
					// console.log(this.companiesChecks)
					this.$toasted.global.defaultSuccess({msg: 'Companhias encontradas.'})
				})
				.catch(e => {
					this.$toasted.global.defaultError({msg: 'Erro ao buscar companhias.'})
				})
		},

		getFilterCompanies() {

			let self = this

				if (self.searchCompanies == '') {
					self.companies = self.companiesGroup
				} else {
					self.companies = self.companiesGroup.filter(company => {
						return ( company.name.match(new RegExp(self.searchCompanies, "i"))
				|| company._id.match(new RegExp(self.searchCompanies, "i"))
				|| company.account_type.match(new RegExp(self.searchCompanies, "i"))
				)
					})
				}
		},

		filterCompaniesCheckNoCheck() {

			return {
				companiesChecked: this.companies.filter(company => {
					let companies = []
					for(let i = 0; i < this.companiesChecks.length; i++) {
						if (company._id == this.companiesChecks[i]) {
							return company
						}
					}
				}).map(company => company._id),
				
				companiesNoChecked: this.companies.filter(company => {
					if (this.companiesChecks.indexOf(company._id) == -1) {
						return company
					}
				}).map(company => company._id)
			}
		},
		
		updateApresentation() {
			this.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`
				
			this.$tallos_admin_api.put('companies/update-apresentation-companies', {
				companiesChecked: this.filterCompaniesCheckNoCheck().companiesChecked,
				companiesNoChecked: this.filterCompaniesCheckNoCheck().companiesNoChecked
			})
				.then(res => {
					this.getFilterCompanies()
					this.$toasted.global.defaultSuccess({msg: 'Companhias atualizas.'})
				})
				.catch(e => {
					this.$toasted.global.defaultError({msg: 'Erro ao atualizar companhias.'})
				})
		}
	},

	created() {
		this.getCompanies()
		document.title = 'Tallos Admin - Empresas Apresentação'
	}
};
</script>

<style scoped>
.div-companies {
  background-color: #FFF;
}

.badge {
  width: 100px;
}
</style>